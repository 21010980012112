import React from "react"
import queryString from "query-string"
import {useStaticQuery, graphql} from "gatsby"

import Card from "../../components/card"
import { FormFieldInput, FormFieldTextArea, FormFieldFile  } from "../../components/form-fields"

import ApplicationFormLogic from "../../components/application-form-logic"
import ApplicationFormContainer from "../../components/application-form-container"

export class NonProfitProjectForm extends ApplicationFormLogic {
  render() {
    return (
      <ApplicationFormContainer
        data={this.props.data}
        state={this.state}
        closeModal={this.closeModal}
        handleSubmit={this.handleSubmit}
        handleSave={this.handleSave}
        >
        <Card sectionTitle="Project" sectionSubtitle="">
          <FormFieldInput
            labelText="Project Name"
            name="projectName"
            type="text"
            value={this.state.projectName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Neighborhood Name"
            name="neighborhoodName"
            type="text"
            value={this.state.neighborhoodName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe the target community and its needs."
            name="projectCommunity"
            value={this.state.projectCommunity}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe the project (size, number of units
                      etc., cost per square foot), and its total cost and
                      financing structure."
            name="projectDescription"
            value={this.state.projectDescription}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe the organization's role in the project."
            name="projectRole"
            value={this.state.projectRole}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Describe the community's involvement in the planning
                      of the project."
            name="projectEngagement"
            value={this.state.projectEngagement}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="How has the project responded to the community's needs?"
            name="projectNeed"
            value={this.state.projectNeed}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe any challenges or obstacles overcome
                      in developing the project."
            name="projectObstacles"
            value={this.state.projectObstacles}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Briefly describe any innovation in financing, design
                       or construction that make this project unique."
            name="projectInnovations"
            value={this.state.projectInnovations}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="What further impact has the project had on the
                      surrounding area? Has the project spurred additional
                      investment in the community?"
            name="projectImpact"
            value={this.state.projectImpact}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>


        <Card sectionTitle="Media" sectionSubtitle={this.props.data.mediaSectionSubhead}>
          <FormFieldFile
            labelText="Project Budget"
            allowMultiple={false}
            maxFiles={1}
            acceptedFileTypes={[
              'application/pdf',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            ]}
            handleProcessFile={this.handleProcessBudget}
            handleRemoveFile={this.handleRemoveBudget}
            files={this.state.budget}
            disabled={this.state.submitted}
            name="budget"
            helpText="Only PDF, .xlsx, and .docx files are accepted. Files must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldFile
            labelText="Supporting Photos"
            handleProcessFile={this.handleProcessFile}
            handleProcessFiles={this.handleProcessFiles}
            handleRemoveFile={this.handleRemoveFile}
            files={this.state.files}
            disabled={this.state.submitted}
            name="uploadPhotos"
            helpText="Only image files (.png, .jpg, etc.) are accepted. Images must be a maximum of 5MB in size."
            required="required"
          />

          <FormFieldInput
            labelText="Video Link"
            name="videoLink"
            type="text"
            value={this.state.videoLink}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            helpText="Link to a video hosted on a video-sharing service like Dropbox or YouTube."
          />
        </Card>

        <Card sectionTitle="Contact" sectionSubtitle="">
          <FormFieldInput
            labelText="Name"
            name="applicantName"
            type="text"
            value={this.state.applicantName}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Organization"
            name="applicantOrganization"
            type="text"
            value={this.state.applicantOrganization}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Title"
            name="applicantTitle"
            type="text"
            value={this.state.applicantTitle}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldTextArea
            labelText="Address"
            name="applicantAddress"
            type="text"
            value={this.state.applicantAddress}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />

          <FormFieldInput
            labelText="Phone Number"
            name="applicantPhone"
            type="text"
            value={this.state.applicantPhone}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="Email"
            name="applicantEmail"
            type="text"
            value={this.state.applicantEmail}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
            required="required"
          />

          <FormFieldInput
            labelText="How did you learn about the opportunity to apply for this award?"
            name="applicantReferral"
            type="text"
            value={this.state.applicantReferral}
            onChange={this.handleInputChange}
            disabled={this.state.submitted}
          />
        </Card>
      </ApplicationFormContainer>
    );
  }
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: {eq: "applications/non-profit-project.jpg"}) {
        childImageSharp {
          fluid(maxWidth: 1000, maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  data.applicationType="non-profit-project"
  data.fields=[
   'projectName', 'neighborhoodName',
   'projectCommunity', 'projectDescription',
   'projectRole', 'projectEngagement',
   'projectNeed', 'projectObstacles',
   'projectInnovations', 'projectImpact',
   'videoLink',
   'applicantName', 'applicantOrganization', 'applicantTitle',
   'applicantAddress', 'applicantPhone', 'applicantEmail', 
   'applicantReferral'
  ]

  data.title="Non-Profit Project Award"
  data.titleText="The Richard H. Driehaus Foundation Award for Outstanding Non-Profit Neighborhood Real Estate Project"
  data.awardDescription="The Richard H. Driehaus Foundation Award for Outstanding Non-Profit Neighborhood Real Estate Project recognizes a community development corporation for a specific real estate project that has contributed significantly to the enhancement of the community."
  data.awardLink="https://lisc-cnda.org/awards/non-profit-project"
  data.headerImagePath="applications/non-profit-project.jpg"
  data.headerImageLink="https://map.lisc-cnda.org/2019/cnda/lawndale-christian-health-center-for-the-farm-on-ogden"
  data.headerImageProject="Lawndale Christian Health Center for the Farm on Ogden"
  data.headerImageDescription=", a 2019 Non-Profit Project Award winner"
  data.mediaSectionSubhead="A project budget and 1-2 project photos are required. Up to 10 photos and a video link can be included but are not required."
  data.optionalMedia=true

  return (
    <NonProfitProjectForm
      data={data}
      uuid={queryString.parse(props.location.search).uuid}
  />
  )
}
